import React, { useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { css, cx } from 'emotion';
import { useStyles, useTheme2 } from '@grafana/ui';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { EquipmentStatesOptions } from 'types';

interface Props extends PanelProps<EquipmentStatesOptions> { }

export const EquipmentStatesPanel: React.FC<Props> = ({ options, data, height }) => {
    const theme = useTheme2();
    const styles = useStyles(() => {
        return {
            container: css`
                height: 100%;
            `,
        };
    });

    const chartRef = useRef<any>();

    const getChartOptions = () => {
        const resetHighlights = (chart: any, category: any) => {
            if (Array.isArray(chart.series) && chart.series.length > 0) {
                chart?.series[0]?.data.forEach((data: any) => {
                    data.setState('normal');
                    data.setState('inactive');
                });
                category.setState('hover');
            };
        };

        const chartOptions = {
            series: chartData(),
            chart: {
                spacing: [4, 4, 8, 4],
                type: 'pie',
                backgroundColor: '#ffffff00',
                events: {
                    load: function () {
                        const refreshTooltip = () => {
                            const category = (
                                (this as any).series[0]?.data.find((d: any) => d.name === data.series[0].refId) ?? (this as any).series[0].data[0]
                            );

                            resetHighlights(this, category);

                            (this as any).tooltip.refresh(category);
                        };
                        refreshTooltip();
                        (this as any).pointer.reset = refreshTooltip;
                    },
                },
            },
            colors: ['#19fa33'],
            credits: { enabled: false },
            legend: {
                align: 'center',
                enabled: options.showLegend,
                itemDistance: 10,
                margin: 0,
                padding: 8,
                symbolPadding: 5,
                itemStyle: {
                    color: theme.isLight ? 'black' : 'white',
                },
                itemHoverStyle: {
                    color: theme.isLight ? 'black' : 'white',
                },
            },
            plotOptions: {
                bar: {
                    stacking: 'normal',
                },
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    innerSize: '85%',
                    showInLegend: true,
                    size: '90%',
                    allowPointSelect: true,
                }
            },
            title: {
                margin: 0,
                text: '',
            },
            'series[0]tooltip': {
                valueDecimals: 0,
            },
            xAxis: {
                categories: [
                    'Value',
                ],
                lineWidth: 0,
            },
            tooltip: {
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                useHTML: true,
                className: 'donutChartTooltip',
                style: {
                    fontFamily: 'Roboto; sans-serif',
                    textAlign: 'center',
                },
                headerFormat: (
                    `
                    <span style='color: {point.color}; font-size: calc(${height} * 0.8%); margin-bottom: 5px;font-family: Roboto,Helvetica Neue,Arial,sans-serif; '>
                        {point.key}
                    </span>
                    <br />
                    `
                ),
                pointFormat: (
                    `
                    <span style='color: {point.color};'>
                        <span style='font-size: calc(${height} * 1.2%);font-family: Roboto,Helvetica Neue,Arial,sans-serif; font-weight: bold;'>
                            {point.percentage:.0f}<span>%</span>
                        </span>
                        <br />
                        <span style='font-size: calc(${height} * 0.7%); font-family: Roboto,Helvetica Neue,Arial,sans-serif; '>
                            [{point.y}/{point.total}]
                        </span>
                    </span>
                    `
                ),
                // tooltip custom positioning
                positioner: function (labelWidth: any, labelHeight: any, point: any): any {
                    const titleHeight = (this as any).chart?.title?.getBBox?.()?.height ?? 0;
                    return {
                        x: ((this as any).chart.chartWidth - labelWidth) / 2,
                        y: titleHeight + (this as any).chart.spacing[0] + (((this as any).chart.plotHeight - labelHeight) / 2),
                    };
                },
            },
        };
        let selectedBand: any = null;
        chartOptions.series.forEach((series, index) => {
            series.events = {
                mouseOver: () => selectedBand = index,
                mouseOut: function () {
                    selectedBand = null;
                    setTimeout(() => {
                        if (selectedBand === null) {
                            const category = ((this as any).chart.series[0].data.find((d: any) => d.name === data.series[0].refId)
                                ?? (this as any).chart.series[0].data[0]);
                            resetHighlights(this, category);
                            (this as any).chart.tooltip.refresh(category);
                        };
                    }, 50);
                },
            };
        });
        return chartOptions;
    };

    const chartData = () => {
        const series = [];
        if (data.series.length > 0) {
            const seriesData = data.series?.map((s, index) => ({
                name: s.refId,
                y: s.fields.filter((f) => f.name === 'cnt')[0]?.values.toArray()[0] ?? 0,
                color: options.stateColors[index] ?? '#fff',
            }));
            series.push({
                data: seriesData,
                events: {},
            });
        }
        return series;
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            constructorType={'chart'}
            options={getChartOptions()}
            containerProps={{ className: cx(styles.container) }}
            ref={(ref) => chartRef.current = ref}
        />
    );
};
