import { PanelPlugin } from '@grafana/data';
import { EquipmentStatesOptions } from './types';
import { EquipmentStatesPanel } from './EquipmentStatesPanel';

export const plugin = new PanelPlugin<EquipmentStatesOptions>(EquipmentStatesPanel).setPanelOptions(builder => {
    return builder
        .addBooleanSwitch({
            name: 'Show legend',
            path: 'showLegend',
            description: 'Enable to show legend',
            defaultValue: false,
        })
        .addStringArray({
            name: 'Color for states',
            path: 'stateColors',
            description: 'Colors for states, the order of these colors follow your series order',
            defaultValue: ['#4dff70', '#e8e149'],
        });
});
